'use client';

import * as Sentry from '@sentry/nextjs';
import Error       from 'next/error';
import {useEffect} from 'react';

export default function GlobalError(props: { error: Error }) {
    useEffect(() => {
        Sentry.captureException(props.error);
    }, [props.error]);

    return (
        <html>
        <body>
        <Error statusCode={undefined as any}/>
        </body>
        </html>
    );
}